"use strict";

var _interopRequireDefault = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/www/wwwroot/canteen.webress.com/nutritional_diet/frontend_admin/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var echarts = _interopRequireWildcard(require("echarts"));
var _axios = _interopRequireDefault(require("axios"));
var _vueSeamlessScroll = _interopRequireDefault(require("vue-seamless-scroll"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var ResizeObserverIns = null;
var _default = exports.default = {
  components: {
    vueSeamlessScroll: _vueSeamlessScroll.default
  },
  data: function data() {
    return {
      title: '——',
      flag: true,
      list: '',
      allDay: {
        calories: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        carbohydrate: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        protein: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        fat: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        vitamin_a: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        vitamin_e: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        vitamin_b1: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        vitamin_b2: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        vitamin_b3: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        vitamin_c: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        calcium: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        iron: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        zinc: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        },
        sodium: {
          intake: '---',
          standard: '---',
          rate: '---',
          grade: '无'
        }
      },
      base: {
        member_title: ''
      },
      cookbook: {
        breakfast: [],
        lunch: [],
        supper: []
      },
      ingTypesCounts: {
        ddl: '--',
        rdl: '--',
        sgl: '--',
        gwl: '--',
        jgl: 0,
        qtl: 0
      },
      ingTypesSum: [0, 0],
      mealsEnergyRate: {
        breakfast: {
          intake: '---',
          lable: '---',
          overflow: '---',
          recommend: '---'
        },
        lunch: {
          intake: '---',
          lable: '---',
          overflow: '---',
          recommend: '---'
        },
        supper: {
          intake: '---',
          lable: '---',
          overflow: '---',
          recommend: '---'
        },
        total: {
          intake: '---',
          lable: '---',
          overflow: '---',
          recommend: '---'
        }
      },
      nutritionalRate: [],
      score: 0,
      today: '',
      week: '',
      scoreGrade: '',
      scale: 1,
      classOption: {
        step: 0.5,
        limitMoveNum: 10,
        hoverStop: true
      },
      classOption1: {
        step: 0.5,
        limitMoveNum: 10,
        hoverStop: true
      },
      classOption2: {
        step: 0.5,
        limitMoveNum: 10,
        hoverStop: true
      }
    };
  },
  computed: {
    computedStyle: function computedStyle() {
      return {
        transform: "scale(".concat(this.scale, ")"),
        'transform-origin': '0 0'
      };
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.screenResize();
    ResizeObserverIns = new ResizeObserver(function () {
      _this.screenResize();
    });
    ResizeObserverIns.observe(this.$refs.fullScreen);
    this.getdate();
    this.getlist();
    // if(this.flag){
    //   this.getechart()
    // }
  },
  beforeDestroy: function beforeDestroy() {
    ResizeObserverIns && ResizeObserverIns.disconnect();
  },
  methods: {
    decide: function decide() {
      var a = '热量摄入在目标范围内，满足平衡膳食营养标准中要求的达到推荐供给量的85%-100%，';
      var b = '热量摄入不在目标范围内，未满足平衡膳食营养标准中要求的达到推荐供给量的85%-100%，';
      var c = '三大宏量营养素中蛋白质、脂肪、碳水化合物满足产能目标范围，';
      var d = '三大宏量营养素中蛋白质、脂肪未满足产能目标范围碳水化合物满足产能目标范围，';
      var e = '优质蛋白 (豆类蛋白+动物蛋白) 的供给占总蛋白质摄入的50%以上，满足平衡膳食营养标准，';
      var f = '优质蛋白 (豆类蛋白+动物蛋白) 的供给未达到总蛋白质摄入的50%，无法满足平衡膳食营养标准，';
      var g = '动物脂肪和植物脂肪，其 最科学合理比例是1: 2，才能实现科学平衡，互相补充，建议适当调整。';
      var a1 = '';
      var a2 = '';
      var a3 = '';
      if (this.nutritionalRate && this.nutritionalRate.length) {
        var o = this.nutritionalRate[1].elList['优质蛋白质'];
        if (o >= 50) {
          a3 = e;
        } else {
          a3 = f;
        }
        var t = this.nutritionalRate[0].elList['碳水化合物'];
        var t1 = this.nutritionalRate[0].elList['脂肪'];
        var t2 = this.nutritionalRate[0].elList['蛋白质'];
        if (t + t1 + t2 > 85) {
          //
          a1 = a;
        } else {
          a1 = b;
        }
        if (this.allDay.carbohydrate.rate > 85 && this.allDay.fat.rate > 85 && this.allDay.protein.rate > 85 && this.allDay.carbohydrate.rate <= 100 && this.allDay.fat.rate <= 100 && this.allDay.protein.rate <= 100) {
          a2 = c;
        } else {
          if ((this.allDay.carbohydrate.rate < 85 || this.allDay.carbohydrate.rate > 100) && (this.allDay.protein.rate < 85 || this.allDay.protein.rate > 100) && (this.allDay.fat.rate < 85 || this.allDay.fat.rate > 100)) {
            a2 = "\u4E09\u5927\u5B8F\u91CF\u8425\u517B\u7D20\u4E2D\u86CB\u767D\u8D28\u3001\u8102\u80AA\u3001\u78B3\u6C34\u5316\u5408\u7269\u672A\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C";
          }
          // if ((this.allDay.carbohydrate.rate < 85 && this.allDay.protein.rate < 85 && this.allDay.fat.rate < 85) || (
          //     this.allDay.carbohydrate.rate > 100 && this.allDay.protein.rate > 100 && this.allDay.fat.rate > 100)) {
          //   a2 = `三大宏量营养素中蛋白质、脂肪、碳水化合物未满足产能目标范围，`
          // }
          if (this.allDay.protein.rate <= 85 && this.allDay.carbohydrate.rate <= 85 && this.allDay.fat.rate > 85 && this.allDay.fat.rate <= 100) {
            a2 = "\u4E09\u5927\u5B8F\u91CF\u8425\u517B\u7D20\u4E2D\u86CB\u767D\u8D28\u3001\u78B3\u6C34\u5316\u5408\u7269\u672A\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C\u8102\u80AA\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C";
          }
          if (this.allDay.fat.rate <= 85 && this.allDay.protein.rate <= 85 && this.allDay.carbohydrate.rate > 85 && this.allDay.carbohydrate.rate <= 100) {
            a2 = "\u4E09\u5927\u5B8F\u91CF\u8425\u517B\u7D20\u4E2D\u86CB\u767D\u8D28\u3001\u8102\u80AA\u672A\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C\u78B3\u6C34\u5316\u5408\u7269\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C";
          }
          if (this.allDay.fat.rate <= 85 && this.allDay.carbohydrate.rate <= 85 && this.allDay.protein.rate > 85 && this.allDay.protein.rate <= 100) {
            a2 = "\u4E09\u5927\u5B8F\u91CF\u8425\u517B\u7D20\u4E2D\u78B3\u6C34\u5316\u5408\u7269\u3001\u8102\u80AA\u672A\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C\u86CB\u767D\u8D28\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C";
          }
          //
          if (this.allDay.protein.rate <= 85 && this.allDay.carbohydrate.rate > 85 && this.allDay.fat.rate > 85 && this.allDay.carbohydrate.rate <= 100 && this.allDay.fat.rate <= 100) {
            a2 = "\u4E09\u5927\u5B8F\u91CF\u8425\u517B\u7D20\u4E2D\u86CB\u767D\u8D28\u672A\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C\u78B3\u6C34\u5316\u5408\u7269\u3001\u8102\u80AA\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C";
          }
          if (this.allDay.fat.rate <= 85 && this.allDay.protein.rate > 85 && this.allDay.carbohydrate.rate > 85 && this.allDay.protein.rate <= 100 && this.allDay.carbohydrate.rate <= 100) {
            a2 = "\u4E09\u5927\u5B8F\u91CF\u8425\u517B\u7D20\u4E2D\u8102\u80AA\u672A\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C\u78B3\u6C34\u5316\u5408\u7269\u3001\u86CB\u767D\u8D28\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C";
          }
          if (this.allDay.carbohydrate.rate <= 85 && this.allDay.fat.rate > 85 && this.allDay.protein.rate > 85 && this.allDay.fat.rate <= 100 && this.allDay.protein.rate <= 100) {
            a2 = "\u4E09\u5927\u5B8F\u91CF\u8425\u517B\u7D20\u4E2D\u78B3\u6C34\u5316\u5408\u7269\u672A\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C\u86CB\u767D\u8D28\u3001\u8102\u80AA\u6EE1\u8DB3\u4EA7\u80FD\u76EE\u6807\u8303\u56F4\uFF0C";
          }
        }
      }
      var str = a1 + a2 + a3 + g;
      return str;
    },
    getlist: function getlist() {
      var _this2 = this;
      var url = window.location.href;
      var number = this.getURLParameter('number', url);
      // 添加时间戳作为查询参数
      var timestamp = new Date().getTime();
      _axios.default.get("".concat(process.env.VUE_APP_BASE_API, "cookbook/weekly/bigDataScreen?business_number=").concat(number, "&timestamp=").concat(timestamp)).then(function (res) {
        console.log(Array.isArray(res.data.data));
        if (!Array.isArray(res.data.data)) {
          _this2.list = res.data.data;
          _this2.base = _this2.list.base;
          if (_this2.base.business_name) _this2.title = _this2.base.business_name;
          if (!_this2.list.cookbook) {
            return;
          }
          _this2.allDay = _this2.list.allDay;
          _this2.cookbook = _this2.list.cookbook;
          var ingTypesCounts = _this2.list.ingTypesCounts;
          for (var key in ingTypesCounts) {
            if (key == '大豆类') {
              _this2.ingTypesCounts.ddl = ingTypesCounts[key];
            }
            if (key == '肉蛋类') {
              _this2.ingTypesCounts.rdl = ingTypesCounts[key];
            }
            if (key == '蔬果类') {
              _this2.ingTypesCounts.sgl = ingTypesCounts[key];
            }
            if (key == '谷薯类') {
              _this2.ingTypesCounts.gwl = ingTypesCounts[key];
            }
            if (key == '坚果类') {
              _this2.ingTypesCounts.jgl = ingTypesCounts[key];
            }
            if (key == '其他类') {
              _this2.ingTypesCounts.qtl = ingTypesCounts[key];
            }
          }
          var ingTypesSum = String(_this2.list.ingTypesSum);
          _this2.ingTypesSum = ingTypesSum.split('');
          if (_this2.ingTypesSum.length < 2) {
            _this2.ingTypesSum.unshift(0);
          }
          _this2.mealsEnergyRate = _this2.list.mealsEnergyRate;
          _this2.nutritionalRate = _this2.list.nutritionalRate;
          _this2.score = _this2.list.score;
          _this2.scoreGrade = _this2.list.scoreGrade;
          _this2.flag = false;
          _this2.drawLine();
          _this2.getechart();
          setTimeout(function () {
            if (document.querySelector('.big-cookbook') && document.querySelector('.big-cookbook').offsetHeight > 785) {
              _this2.classOption.limitMoveNum = 1;
              _this2.$refs.seamlessScroll.reset();
            }

            // if (document.querySelector('.big-breakfast') && document.querySelector('.big-breakfast')
            //   .offsetHeight > 200) {
            //   this.classOption.limitMoveNum = 1
            //   this.$refs.seamlessScroll.reset()
            // }
            // if (document.querySelector('.big-lunch') && document.querySelector('.big-lunch').offsetHeight >
            //   200) {
            //   this.classOption1.limitMoveNum = 1
            //   this.$refs.seamlessScroll1.reset()
            // }
            // if (document.querySelector('.big-supper') && document.querySelector('.big-supper').offsetHeight >
            //   190) {
            //   this.classOption2.limitMoveNum = 1
            //   this.$refs.seamlessScroll2.reset()
            // }
          }, 100);
        } else {
          _this2.getechart();
        }

        // Math.floor(Number(allDay.iron.intake)*100)/100
      }).catch(function (err) {
        _this2.getechart();
      });
    },
    getURLParameter: function getURLParameter(name, urlsearch) {
      return decodeURIComponent((new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(urlsearch || location.search) || [, ""])[1].replace(/\+/g, "%20")) || null;
    },
    getdate: function getdate() {
      var now = new Date(); // 创建一个Date对象
      var year = now.getFullYear(); // 年
      var month = now.getMonth() + 1; // 月（注意加1）
      var date = now.getDate(); // 日
      var days = ['日', '一', '二', '三', '四', '五', '六'];
      var day = days[now.getDay()]; // 星期几
      this.today = "".concat(year, "-").concat(month, "-").concat(date);
      this.week = "\u661F\u671F".concat(day);
      console.log("\u4ECA\u5929\u662F".concat(year, "\u5E74").concat(month, "\u6708").concat(date, "\u65E5\uFF0C\u661F\u671F").concat(day));
    },
    screenResize: function screenResize() {
      this.scale = this.$refs.fullScreen.offsetWidth / 1920;
    },
    getechart: function getechart() {
      var data = [];
      var data1 = [];
      var data2 = [];
      this.nutritionalRate.forEach(function (item) {
        if (item.title == '关键营养热量比') {
          for (var key in item.elList) {
            var json = {
              value: item.elList[key],
              name: key
            };
            data.push(json);
          }
        }
        if (item.title == "蛋白质来源") {
          for (var _key in item.elList) {
            var _json = {
              value: item.elList[_key],
              name: _key
            };
            data1.push(_json);
          }
        }
        if (item.title == '脂肪来源') {
          for (var _key2 in item.elList) {
            var _json2 = {
              value: item.elList[_key2],
              name: _key2
            };
            data2.push(_json2);
          }
        }
      });
      if (!data.length) {
        data = [{
          value: 0,
          name: '暂无数据'
        }];
      }
      if (!data1.length) {
        data1 = [{
          value: 0,
          name: '暂无数据'
        }];
      }
      if (!data2.length) {
        data2 = [{
          value: 0,
          name: '暂无数据'
        }];
      }
      this.drawLines('myChart1', '关键营养\n热量比', data);
      this.drawLines('myChart2', '蛋白质\n来源', data1);
      this.drawLines('myChart3', '脂肪\n来源', data2);
    },
    drawLines: function drawLines(id, title, data) {
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          top: 'bottom',
          icon: 'circle',
          bottom: '0%',
          formatter: function formatter(name, value) {
            var items = {};
            if (data.length) {
              items = data.filter(function (item) {
                return item.name == name;
              })[0];
            }
            return "".concat(items.value, "% \n").concat(items.name);
          },
          textStyle: {
            padding: [-35, -80, 0, 0],
            color: '#fff'
          }
        },
        series: [{
          name: title,
          type: 'pie',
          radius: ['40%', '65%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          label: {
            normal: {
              color: '#fff',
              align: 'center',
              fontSize: 18,
              formatter: function formatter(params) {
                return title;
              },
              position: 'center',
              show: true
            }
          },
          labelLine: {
            show: false
          },
          itemStyle: {},
          data: data
        }]
      };
      if (this.flag) {
        option.legend.show = false;
        option.series[0].itemStyle = {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
              offset: 1,
              color: '#2BD9FD'
            }, {
              offset: 0,
              color: '#136AED'
            }])
          }
        };
      }
      option && this.$echarts.init(this.$refs[id]).setOption(option);
    },
    drawLine: function drawLine() {
      var placeHolderStyle = {
        normal: {
          label: {
            show: false
          },
          labelLine: {
            show: false
          },
          color: 'rgba(0,0,0,0)',
          borderWidth: 0
        },
        emphasis: {
          color: 'rgba(0,0,0,0)',
          borderWidth: 0
        }
      };
      var option = {
        grid: {
          top: '0',
          left: '10%',
          right: '10%',
          bottom: '5%'
        },
        series: [
        //第1个图表
        {
          type: 'pie',
          hoverAnimation: false,
          //鼠标经过的特效
          radius: ['80%', '65%'],
          center: ['50%', '50%'],
          startAngle: 225,
          labelLine: {
            normal: {
              show: false
            }
          },
          label: {
            normal: {
              rich: {
                a: {
                  color: '#43CF7C',
                  align: 'center',
                  fontWeight: 800,
                  fontSize: 42
                }
              },
              formatter: function formatter(params) {
                return "{a|" + params.value + "}";
              },
              position: 'center',
              show: true
            }
          },
          data: [{
            value: this.score,
            itemStyle: {
              normal: {
                color: '#26278F'
              }
            }
          }, {
            value: this.score,
            itemStyle: placeHolderStyle
          }]
        },
        //上层环形配置
        {
          type: 'pie',
          hoverAnimation: false,
          //鼠标经过的特效
          radius: ['80%', '65%'],
          center: ['50%', '50%'],
          startAngle: 225,
          labelLine: {
            normal: {
              show: false
            }
          },
          data: [{
            value: this.score,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                  offset: 0,
                  color: '#0336FF'
                }, {
                  offset: 1,
                  color: '#01B4FF'
                }])
              }
            }
          }, {
            value: this.score,
            itemStyle: placeHolderStyle
          }]
        }]
      };
      option && this.$echarts.init(this.$refs.myChart).setOption(option);
    }
  }
};